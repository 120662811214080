document.addEventListener("DOMContentLoaded", function() {
    function getParent(ele, parentClass){
        var e = ele;
        while(!e.classList.contains(parentClass)){
          e=e.parentElement;
        }
        return e;
    }
    var elements = document.querySelectorAll('[data-observe-region-size]');
    elements.forEach(function(el){ 
        var parent = getParent(el, 'tncms-region');
        var width = parent.offsetWidth;
        var size = el.getAttribute('data-observe-region-size');
        var useDefaultClassAndSize = size === '';
        if (useDefaultClassAndSize) {
            if (width > 200 && width < 320) {
                el.classList.add('tncms-sm-block');
            } else if (width > 320 && width < 640) {
                el.classList.add('tncms-md-block');
            } else if (width >= 640) {
                el.classList.add('tncms-lg-block');
            }
        } else {
            var sArray = size.split(',');
            for (var x = 0; x < sArray.length; x++) {
                var maxWidth = sArray[x].split(':')[0];
                var className = sArray[x].split(':')[1];
                if (width < maxWidth) {
                    el.classList.add(className);
                }
            }
        }
    });
});